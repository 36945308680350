// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-slider {
  margin-top: 18px;
}
.custom-slider .rs-slider-handle {
  top: -6px;
  background-color: #3498ff;
  text-align: center;
  padding: 3px;
  margin-left: -16px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.custom-slider .rs-slider-handle:hover {
  box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
}
.custom-slider .rs-slider-handle::before {
  display: none;
}
.custom-slider.rs-slider-dragging .rs-slider-handle,
.custom-slider.rs-slider-dragging .rs-slider-handle:hover {
  box-shadow: none;
  transform: scale(1.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/slider.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAFA;EAII,SAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,mEAAA;AACJ;AACI;EACE,8CAAA;AACN;AAEI;EACE,aAAA;AAAN;AAIE;;EAEE,gBAAA;EACA,qBAAA;AAFJ","sourcesContent":[".custom-slider {\n  margin-top: 18px;\n\n  .rs-slider-handle {\n    top: -6px;\n    background-color: #3498ff;\n    text-align: center;\n    padding: 3px;\n    margin-left: -16px;\n    cursor: pointer;\n    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;\n\n    &:hover {\n      box-shadow: 0 0 0 8px rgb(52 152 255 / 25%);\n    }\n\n    &::before {\n      display: none;\n    }\n  }\n\n  &.rs-slider-dragging .rs-slider-handle,\n  &.rs-slider-dragging .rs-slider-handle:hover {\n    box-shadow: none;\n    transform: scale(1.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
